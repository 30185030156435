import { FormControl } from 'native-base'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Input as NInput } from 'native-base'

import 'react-phone-number-input/style.css'
import './phone.css'

export function InputPhone(props: { label: string; name: string; isRequired?: boolean; placeholder?: string }) {
  const form = useFormContext()
  const rules = {
    required: props.isRequired ? `${props.label} is required` : null,
    validate: (data) => (isValidPhoneNumber(data) ? null : `${props.label} is not valid`),
  }
  const { invalid } = form.getFieldState(props.name)
  return (
    <FormControl isInvalid={invalid} isRequired={props.isRequired} flex={1} testID={`field_phone_input`}>
      <FormControl.Label>{props.label}</FormControl.Label>
      <Controller
        control={form.control}
        rules={rules}
        render={({ field }) => {
          const { error } = form.getFieldState(field.name)
          return (
            <>
              <PhoneInput
                defaultCountry="US"
                placeholder={props.placeholder || 'Enter phone number'}
                inputComponent={NInput}
                value={field.value}
                onChange={field.onChange}
              />
              {error && <FormControl.ErrorMessage>{error?.message}</FormControl.ErrorMessage>}
            </>
          )
        }}
        name={props.name}
      />
    </FormControl>
  )
}
