import React from 'react'
import { Input } from './fields'

export function InputEmail(props: { name: string; label: string; isRequired: boolean; placeholder?: string }) {
  const rules = {
    validate: (data: string) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(data) ? null : `${props.label} is invalid`),
  }

  return (
    <Input
      name={props.name}
      label={props.label}
      isRequired={props.isRequired}
      placeholder={props.placeholder || 'john@store.com'}
      flex="1"
      rules={rules}
    />
  )
}
