import { ResourceAuthorizationAPI } from '../api/resource-authorization/service'
import { Maybe } from 'app/types'
import axios from 'axios'
import formatBackendErrors from '../../common/util'
import React, { useState, useContext, createContext, useEffect, PropsWithChildren } from 'react'
import { Apps, getAPIURL } from '../../api_url'
import { Box, Text } from 'ui/components/responsive-primitives'
import { Error, Center, P, Small } from 'ui'

const AccessGroupCtx = createContext<Maybe<SecureResourceAccess>>(null)

export interface SecureResourceAccess {
  isAuthorized: Record<string, boolean>
  areAllResourcesAuthorized: (
    resource_type: string,
    resource_ids: string[],
    role: string
  ) => Promise<{ is_authorized: boolean }>
}

const users_url = getAPIURL(Apps.USERS_API)

export function SecureResourceAccess(props: {
  view_role: string
  admin_role: string
  other_roles?: string[]
  resource_type: string | null
  resource_id: string | null
  children?: any
}): JSX.Element {
  const [isAuthorized, setIsAuthorized] = useState<Record<string, boolean>>({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const AG_URL = `${users_url}/is_resource_authorized`

  useEffect(() => {
    const other_roles = props.other_roles || []
    const promise_array = [
      ResourceAuthorizationAPI.isResourceAuthorized(props.resource_type, props.resource_id, props.view_role),
      ResourceAuthorizationAPI.isResourceAuthorized(props.resource_type, props.resource_id, props.admin_role),
      ...other_roles.map((r) =>
        ResourceAuthorizationAPI.isResourceAuthorized(props.resource_type, props.resource_id, r)
      ),
    ]

    Promise.all(promise_array)
      .then((res) => {
        const authorizationObj = {
          [props.view_role]: res[0]['is_authorized'],
          [props.admin_role]: res[1]['is_authorized'],
        }

        other_roles.map((i, idx) => (authorizationObj[i] = res[idx + 2]['is_authorized']))
        setIsAuthorized(authorizationObj)
      })
      .catch((err) => setError(formatBackendErrors(err)))
      .finally(() => setLoading(false))
  }, [])

  const areAllResourcesAuthorized = (
    resource_type: string,
    resource_ids: string[],
    role: string
  ): Promise<{ is_authorized: boolean }> => {
    return axios
      .post(AG_URL, { resource_type, resource_ids, role })
      .then((res) => {
        return res.data
      })
      .catch((err) => Promise.reject(formatBackendErrors(err.response.data)))
  }

  if (!!error) {
    return (
      <Center flex={1}>
        <Error>Some error occured</Error>
      </Center>
    )
  }

  if (loading) {
    return (
      <Center flex={1}>
        <Small>authorizing...</Small>
      </Center>
    )
  }

  if (!isAuthorized[props.view_role]) {
    return (
      <Center flex={1}>
        <Error>Unauthorized. Please ensure access on resource!</Error>
      </Center>
    )
  }

  return (
    <AccessGroupCtx.Provider value={{ isAuthorized, areAllResourcesAuthorized }}>
      {props.children}
    </AccessGroupCtx.Provider>
  )
}

export function useSecureResourceAccess(): SecureResourceAccess {
  const SecureResourceAccess = useContext(AccessGroupCtx)
  if (SecureResourceAccess) return SecureResourceAccess
  else throw 'Setup context first'
}

export function useSecureResourceAccessOptional(): SecureResourceAccess | null {
  const SecureResourceAccess = useContext(AccessGroupCtx) || null
  return SecureResourceAccess
}

export function RestrictResource(props: PropsWithChildren<{ role?: string }>): JSX.Element {
  const secureResourceAccess = useSecureResourceAccess()
  if (!secureResourceAccess.isAuthorized.hasOwnProperty(props.role || '')) throw 'role not setup in context'
  if (secureResourceAccess.isAuthorized[props.role || '']) {
    return <>{props.children}</>
  } else {
    return <></>
  }
}
